<template>
  <div class="other-live-room">
    <div class="operate">
      <button class="back" @click="back">{{ lang.return }}</button>
    </div>
    <div class="content">
      <el-scrollbar style="height: 100%">
        <div class="room-list">
          <div class="room-item" v-for="(item, index) in list" :key="index" @click="seeLive(item)">
            <div class="head"  :style="{backgroundImage:item.getStudentInfo.getOnliveRoom===null?'':'url('+item.getStudentInfo.getOnliveRoom.cover+')'}">
              <div class="live-base-info">
                <div class="people-num">
                  <i class="el-icon-user-solid icon"></i>
                  <div class="num">{{ item.online_total_number }}</div>
                </div>
                <div
                  class="state-box"
                  :class="[item.state === 1 ? 'live-ing' : 'live-end']"
                  v-if ='item.getStudentInfo.getOnliveRoom === null || item.getStudentInfo.getOnliveRoom.is_has_push === 0'
                >
                  <i class="iconfont icon" v-if="item.state == 1">&#xe63c;</i>
                  <div class="state" v-if="!!item.getStudentInfo.getOnliveRoom">{{ liveState[item.getStudentInfo.getOnliveRoom.status] }}</div>
                  <div class="state" v-else>{{ lang.no_live }}</div>
                </div>
                <div
                  class="state-box"
                  :class="[item.getStudentInfo.getOnliveRoom.status === 1 && item.getStudentInfo.getOnliveRoom.is_has_push === 1? 'live-ing' : 'live-end']"
                  v-else
                >
                  <i class="iconfont icon" v-if="item.getStudentInfo.getOnliveRoom.status === 1 && item.getStudentInfo.getOnliveRoom.is_has_push === 1">&#xe63c;</i>
                  <div class="state">{{ liveState[item.getStudentInfo.getOnliveRoom.status] }}</div>
                </div>
              </div>
            </div>
            <div class="live-info">
              <div class="live-title" v-if="item.getStudentInfo.getOnliveRoom ==null" >{{ lang.no_live }}</div>
              <div class="live-title" v-else >{{item.getStudentInfo.getOnliveRoom.title}}</div>
              <div class="user-info">
                <div v-if="item.getStudentInfo.getOnliveRoom ==null" class="avatar">
                  <img
                    class="avatar-img"
                    :src="item.avatar"
                    :alt="item.username"
                    :title="item.username"
                  />
                </div>
                 <div v-else class="avatar">
                  <img
                    class="avatar-img"
                    :src="item.getStudentInfo.getOnliveRoom.avatar"
                    :alt="item.getStudentInfo.getOnliveRoom.nickname"
                    :title="item.getStudentInfo.getOnliveRoom.nickname"
                  />
                </div>
                <div v-if="item.getStudentInfo.getOnliveRoom ==null" class="username">{{ lang.no_anchor }}</div>
                <div v-else class="username">{{item.getStudentInfo.getOnliveRoom.nickname}}</div>
              </div>
            </div>
          </div>
        </div>
        
      </el-scrollbar>
    </div>
     <el-pagination
          class="pages-center"
          :current-page="listPages.currentPageNum"
          :page-size="listPages.eachPageNum"
          layout="prev, pager, next, jumper"
          :total="listPages.total"
          @current-change="pageCurrentChange"
        >
        </el-pagination>
  </div>
</template>

<script>
import { otherPeople, otherScript } from "@/utils/apis";
import lang from "@/lang/othersLive"

export default {
  name: "OtherLiveRoom",
  data() {
    return {
      list: [],
         listPages: {
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      lang,
      liveState: [lang.no_on_air, lang.living, lang.lived, lang.living_suspend],
      img:'https://tse1-mm.cn.bing.net/th/id/R-C.35bcc4c4b02ffbfba073b807044b6e27?rik=gjZqaq%2b6HSBX2A&riu=http%3a%2f%2f5b0988e595225.cdn.sohucs.com%2fimages%2f20200505%2f2ea8ba335e9f4f3eac817338cfc5f3e0.jpeg&ehk=MG1RvptD6F%2bZNf6%2fMmXafOu6AlO6oe1qQIv35X2xd%2fE%3d&risl=&pid=ImgRaw&r=0'
    };
  },
  computed: {},
  components: {},

  
  mounted() {
    // new Array(10).fill(1).forEach(() =>
    //   this.list.push({
    //     title: "直播间标题",
    //     username: "root",
    //     avatar: require("assets/image/blank_headPic.png"),
    //     bgImg:
    //       "https://tse1-mm.cn.bing.net/th/id/R-C.35bcc4c4b02ffbfba073b807044b6e27?rik=gjZqaq%2b6HSBX2A&riu=http%3a%2f%2f5b0988e595225.cdn.sohucs.com%2fimages%2f20200505%2f2ea8ba335e9f4f3eac817338cfc5f3e0.jpeg&ehk=MG1RvptD6F%2bZNf6%2fMmXafOu6AlO6oe1qQIv35X2xd%2fE%3d&risl=&pid=ImgRaw&r=0",
    //     state: parseInt(Math.random() * 3) + 1,
    //     num: 5676,
    //   })
    // );
    this.getDate();
  },
  methods: {
      // 切换分页
    pageCurrentChange(val) {
      this.listPages.currentPageNum = val;
      this.getDate();
    },
    back() {
      this.$router.back();
    },
    getDate() {
      let params = {
        train_id: JSON.parse(localStorage.getItem("train_id")),
        page:this.listPages.currentPageNum,
        limit:this.listPages.eachPageNum
      };
      otherPeople(params)
        .then((res) => {
          if (res.code == 200) {
             this.list = res.data.list
             this.listPages.total = res.data.total
          }
        })
        .catch((err) => {
          console.error("err", err);
        });
    },
    async seeLive(item) {
      let scriptInfo = {};
      let params = {train_id: item.getStudentInfo.getOnliveRoom.train_id};
      let res = await otherScript(params)
      if (res.code == 200) {
        scriptInfo = res.data
      }
      
      let getLiveUrlLast = item.getStudentInfo.getLiveUrl.slice(-1)[0];
      let LiveUrlLast = getLiveUrlLast.webrtc_rtmp.trim();
      let routeUrl = this.$router.resolve({
        name: 'otherStuLiveRoom',
        path: '/otherStuLiveRoom',
        query: {
          tid: item.getStudentInfo.getOnliveRoom.user_id,
          bind_id: Number(localStorage.getItem('studentId')),
          t_Train_id: item.getStudentInfo.getOnliveRoom.train_id,
          t_Room_id: item.getStudentInfo.getOnliveRoom.id,
          play_url: LiveUrlLast,
          play_url_rtmp: getLiveUrlLast.play_rtmp,
          script_id: scriptInfo.script_id,
          advert_id: scriptInfo.advert_id,
        }
      });
      window.open(routeUrl.href, "_blank");
    }
  },
};
</script>

<style scoped lang="scss">
.other-live-room {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 30px;
  overflow: hidden;
  background-image: linear-gradient(to right, #070932, #12144d);
  .operate {
    margin-bottom: 20px;
    text-align: end;
    .back {
      position: relative;
      width: 90px;
      height: 40px;
      color: #fff;
      font-size: 16px;
      border: none;
      background-color: #4b45ff;
      border-radius: 4px;
      cursor: pointer;
      &:hover::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
  .content {
    box-sizing: border-box;
    flex: 1;
    overflow: hidden;
    .room-list {
      box-sizing: border-box;
      display: grid;
      grid-template-columns: repeat(auto-fill, 250px);
      row-gap: 40px;
      column-gap: 20px;
      justify-content: space-between;
      padding-right: 1px;
      .room-item {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        width: 250px;
        height: 300px;
        border-radius: 20px;
        overflow: hidden;
        border: 1px solid transparent;
        cursor: pointer;
        &:hover {
          border-color: #4b45ff;
        }
        .head {
          display: flex;
          align-items: flex-end;
          width: 100%;
          height: 204px;
          background-image: url("https://tse1-mm.cn.bing.net/th/id/R-C.35bcc4c4b02ffbfba073b807044b6e27?rik=gjZqaq%2b6HSBX2A&riu=http%3a%2f%2f5b0988e595225.cdn.sohucs.com%2fimages%2f20200505%2f2ea8ba335e9f4f3eac817338cfc5f3e0.jpeg&ehk=MG1RvptD6F%2bZNf6%2fMmXafOu6AlO6oe1qQIv35X2xd%2fE%3d&risl=&pid=ImgRaw&r=0");
          background-size: 100% 100%;
          .live-base-info {
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 50px;
            padding: 0 10px;
            background-color: rgba(0, 0, 0, 0.5);
            .people-num {
              display: flex;
              align-items: center;
              color: #fff;
              font-size: 16px;
              .icon {
                margin-right: 10px;
                font-size: 20px;
              }
            }
            .state-box {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 80px;
              height: 26px;
              font-size: 14px;
              border-radius: 4px;
              .icon {
                margin-right: 4px;
                font-size: 12px;
              }
            }
            .live-ing {
              color: #fff;
              background-color: #fb0f4b;
            }
            .no-start,
            .live-end {
              color: #5b5b6d;
              background-color: #cfd1db;
            }
          }
        }
        .live-info {
          flex: 1;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding: 0 13px;
          color: #fff;
          .live-title {
            margin-bottom: 10px;
            font-size: 16px;
          }
          .user-info {
            display: flex;
            align-items: center;
            .avatar {
              width: 24px;
              height: 24px;
              margin-right: 9px;
              border-radius: 50%;
              .avatar-img {
                width: 100%;
                height: 100%;
                vertical-align: middle;
              }
            }
            .username {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
::v-deep .el-scrollbar__wrap {
  overflow-x: hidden;
}
::v-deep .pages-center{
  background: none;
  padding: 0px 0px !important;
  margin-top: 10px;
}
::v-deep .el-pagination button:disabled{
      background: none;
          color: #909399
}
::v-deep .el-pagination .btn-next{
   background: none;
      color: #fff
}
::v-deep .el-pagination .btn-prev{
    background: none;
          color: #fff
}
::v-deep .el-pager li{
    background: none;
    color: #fff !important;
}
</style>
